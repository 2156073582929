class TokenResponseDto {
  email: string
  firstName: string
  lastName: string
}

enum TokenType {
  Registration = 'Registration',
  Forgotpassword = 'Forgotpassword',
  EmailConfirmation = 'EmailConfirmation',
}

enum PreviewTokenType {
  View = 'View',
  Edit = 'Edit',
}

export { TokenResponseDto, TokenType, PreviewTokenType }