<ion-app *ngIf="appLoaded">
  <ion-split-pane when="md" contentId="main" [disabled]="previewModeType !== 'Edit'">
    <app-style-configurator *ngIf="previewMode && isDesktop()"></app-style-configurator>
    <div class="ion-page" id="main">
      <ion-icon *ngIf="previewMode && !isDesktop() && previewModeType === 'Edit'" name="color-palette-outline" class="theme-configurator-icon" (click)="openThemeConfiguratorMenu()"></ion-icon>
      <ion-content>
        <ion-header *ngIf="showMenu()">
          <app-header>
          </app-header>
        </ion-header>
        <app-mobile-menu *ngIf="showMenu()"></app-mobile-menu>
        <ion-router-outlet [ngClass]="{'margin-header': showMenu()}"></ion-router-outlet>
        <tab-monitor></tab-monitor>
      </ion-content>
    </div>
  </ion-split-pane>
  <ion-menu side="end" menuId="theme-configurator-menu" *ngIf="!isDesktop() && previewMode" contentId="main">
    <ion-menu-toggle menu="end">
      <ion-icon class="theme-configurator-close-icon" name="close-circle-outline"></ion-icon>
    </ion-menu-toggle>
    <ion-content class="ion-padding">
      <app-style-configurator></app-style-configurator>
    </ion-content>
  </ion-menu>
</ion-app>