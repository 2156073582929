export class FileDto {
  rId: number
  name: string
  key: string
  url: string
}

export class UpsertFileDto {
  rId: number
  fileName: string
  fileKey: string
  content: string
}

export class BrandFileKeys {
  //unique key
  static Favicon = 'Favicon'
  static Logo = 'Logo'
  static LogoWhite = 'LogoWhite'
  static CardArt = 'CardArt'
  static RegisterBackground = 'RegisterBackground'
  static HomeBackground = 'HomeBackground'
  static LoginWatermark = 'LoginWatermark'

  //multiple keys
  static MarketingBanner = 'MarketingBanner'

  //website global footer
  static WebsiteTermsOfUse = 'WebsiteTermsOfUse'
  static CRBPrivacyPolicy = 'CRBPrivacyPolicy'

  //program specific
  static ProgramFAQ = 'ProgramFAQ'
  static ProgramDepositAgreement = 'ProgramDepositAgreement'
  static ProgramPrivacyPolicy = 'ProgramPrivacyPolicy'

  //portal specific
  static PortalFAQ = 'PortalFAQ'
}

export const UniqueFileKeys = [
  BrandFileKeys.Favicon,
  BrandFileKeys.Logo,
  BrandFileKeys.LogoWhite,
  BrandFileKeys.CardArt,
  BrandFileKeys.RegisterBackground,
  BrandFileKeys.HomeBackground,
  BrandFileKeys.LoginWatermark
]

export const WebsiteGlobalFooter = [
  BrandFileKeys.WebsiteTermsOfUse
]

export const PrivacyPolicy = [
  BrandFileKeys.CRBPrivacyPolicy,
  BrandFileKeys.ProgramPrivacyPolicy,
]

export const InfoActionMenuItems = [
  BrandFileKeys.ProgramFAQ,
  BrandFileKeys.ProgramDepositAgreement,
]

export const PreloginInfoItems = [
  BrandFileKeys.PortalFAQ,
]