export const environment = {
  appVersion: '#{{VERSION}}#',
  production: true,
  //api:
  apiUrl: 'https://fd-slp-crb-dev-hmbuf0aseqb0fwbg.a01.azurefd.net/api',
  apiVersion: '1.0',
  //Okta:
  clientId: '0oa6b7t8bhReKbfvk697',
  issuer: 'https://crb-dev-viewaccount.okta.com/oauth2/aus6b7v6raHqurEyq697',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,

  // Google Analytics
  measurementId: 'G-B76XQ3XFQV',

  //Space Chat
  spaceChat: 'https://d2qj96sl31njol.cloudfront.net/scripts/ChatModule_c_u.js',
  spaceAuthenticatedChat: 'https://d2qj96sl31njol.cloudfront.net/scripts/ChatModule_c_a.js',

  //Slider
  sliderDelay: 5000
}
