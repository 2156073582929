import { Injectable } from '@angular/core'
import { Account } from 'src/app/account/models/account.model'
import { Card } from 'src/app/card/activate/models/card.model'
import { FileDto } from '../api/models/file.model'
import { UserApiService } from '../../../../src/security/services/users/userApi.service'
import { UserInfoDto } from 'src/security/models'
import { CRBConfigDto, CRBConfigSettingDto } from '../api/models/configuration.model'
import { BrandColors } from '../ui/brandService.service'
import { PreviewTokenType } from 'src/security/models/token.model'

@Injectable({
  providedIn: 'root',
})
export class CacheService {

  private SLP_USER_INFO = 'slp-user-info'
  private SLP_CARD_REFERENCE_NUMBER = 'slp-card-reference-number'
  private SLP_SELECTED_ACCOUNT = 'slp-selected-account'
  private SLP_CARDS = 'slp-cards'
  private SLP_STATEMENTS_PAGE = 'slp-statements-page'
  private SLP_PREVIEW_MODE = 'slp-preview-mode'
   private SLP_PREVIEW_MODE_TYPE = 'slp-preview-mode-type'
  private SLP_PREVIEW_TOKEN = 'slp-preview-token'
  private SLP_CONFIGURED_BRAND_COLORS = 'slp-configured-brand-colors'
  private SLP_CONFIGURED_BRAND_FILES = 'slp-configured-brand-files'
  private SLP_CONFIGURED_BRAND_SETTINGS = 'slp-configured-brand-settings'

  //this settings are always loaded when page refresh
  private SITE_CRB_CONFIG = 'crb-config'
  private SITE_BRAND_FILES = 'brand-files'
  private SITE_BRAND_CONFIG_SETTINGS = 'brand-config-settings'

  constructor(private userApiService: UserApiService) { }

  public async getUserInfo(): Promise<UserInfoDto> {
    let userInfo = localStorage.getItem(this.SLP_USER_INFO)
    if (userInfo == null) {
      userInfo = JSON.stringify(await this.userApiService.getUserInfo())
      localStorage.setItem(this.SLP_USER_INFO, userInfo)
    }
    return JSON.parse(userInfo) as UserInfoDto
  }

  public getSelectedAccount(): Account {
    const selectedAccount = localStorage.getItem(this.SLP_SELECTED_ACCOUNT)
    if (selectedAccount === 'undefined') {
      return null
    }
    return JSON.parse(selectedAccount) as Account
  }

  public setSelectedAccount(selectedAccount: Account): void {
    const jsonAccount = JSON.stringify(selectedAccount)
    localStorage.setItem(this.SLP_SELECTED_ACCOUNT, jsonAccount)
  }

  public getPreviewMode(): boolean {
    const previewMode = sessionStorage.getItem(this.SLP_PREVIEW_MODE)
    if (previewMode === 'undefined') {
      return false
    }
    return JSON.parse(previewMode) as boolean
  }

  public setPreviewMode(isOn: boolean): void {
    const mode = JSON.stringify(isOn)
    sessionStorage.setItem(this.SLP_PREVIEW_MODE, mode)
  }

  public getPreviewModeType(): PreviewTokenType {
    const previewMode = sessionStorage.getItem(this.SLP_PREVIEW_MODE_TYPE)
    if (previewMode === 'undefined') {
      return null
    }
    return JSON.parse(previewMode) as PreviewTokenType
  }

  public setPreviewModeType(type: PreviewTokenType): void {
    const mode = JSON.stringify(type)
    sessionStorage.setItem(this.SLP_PREVIEW_MODE_TYPE, mode)
  }

  public setPreviewToken(token: string): void {
    const t = JSON.stringify(token)
    sessionStorage.setItem(this.SLP_PREVIEW_TOKEN, t)
  }

  public getPreviewToken(): string {
    const previewToken = sessionStorage.getItem(this.SLP_PREVIEW_TOKEN)
    if (previewToken === 'undefined') {
      return null
    }
    return JSON.parse(previewToken) as string
  }

  public getConfiguredBrandConfigSettings(): CRBConfigSettingDto[] {
    const brandSettings = sessionStorage.getItem(this.SLP_CONFIGURED_BRAND_SETTINGS)
    if (brandSettings === 'undefined') {
      return null
    }
    return JSON.parse(brandSettings) as CRBConfigSettingDto[]
  }

  public setConfiguredBrandConfigSettings(brandSettings: CRBConfigSettingDto[]): void {
    const jsonBrandSettings = JSON.stringify(brandSettings)
    sessionStorage.setItem(this.SLP_CONFIGURED_BRAND_SETTINGS, jsonBrandSettings)
  }

  public getConfiguredBrandingColors(): BrandColors {
    const brandColors = sessionStorage.getItem(this.SLP_CONFIGURED_BRAND_COLORS)
    if (brandColors === 'undefined') {
      return null
    }
    return JSON.parse(brandColors) as BrandColors
  }

  public setConfiguredBrandingColors(brandColors: BrandColors): void {
    const jsonBrandColors = JSON.stringify(brandColors)
    sessionStorage.setItem(this.SLP_CONFIGURED_BRAND_COLORS, jsonBrandColors)
  }

  public getConfiguredBrandingFiles(): FileDto[] {
    const brandFiles = sessionStorage.getItem(this.SLP_CONFIGURED_BRAND_FILES)
    if (brandFiles === 'undefined') {
      return null
    }
    return JSON.parse(brandFiles) as FileDto[]
  }

  public setConfiguredBrandingFiles(brandFiles: FileDto[]): void {
    const jsonBrandFiles = JSON.stringify(brandFiles)
    sessionStorage.setItem(this.SLP_CONFIGURED_BRAND_FILES, jsonBrandFiles)
  }

  public getCards(): Card[] {
    const cards = localStorage.getItem(this.SLP_CARDS)
    if (cards === 'undefined') {
      return null as Card[]
    }
    return JSON.parse(cards) as Card[]
  }

  public setCards(cards: Card[]): void {
    const jsonAccount = JSON.stringify(cards)
    localStorage.setItem(this.SLP_CARDS, jsonAccount)
  }

  public getCardReferenceNumber(): string {
    const cardReferenceNumber = localStorage.getItem(this.SLP_CARD_REFERENCE_NUMBER)
    if (cardReferenceNumber === 'undefined') {
      return null
    }
    return cardReferenceNumber
  }

  public setCardReferenceNumber(cardReferenceNumber: string): void {
    localStorage.setItem(this.SLP_CARD_REFERENCE_NUMBER, cardReferenceNumber)
  }

  public setBrandFiles(files: FileDto[]): void {
    const jsonAccount = JSON.stringify(files)
    localStorage.setItem(this.SITE_BRAND_FILES, jsonAccount)
  }

  public getBrandFiles(): FileDto[] {
    const files = localStorage.getItem(this.SITE_BRAND_FILES)
    if (files === 'undefined') {
      return null as FileDto[]
    }
    return JSON.parse(files) as FileDto[]
  }

  public setCRBConfig(crbConfig: CRBConfigDto): void {
    const jsonCRBConfig = JSON.stringify(crbConfig)
    localStorage.setItem(this.SITE_CRB_CONFIG, jsonCRBConfig)
  }

  public getCRBConfig(): CRBConfigDto {
    const crbConfig = localStorage.getItem(this.SITE_CRB_CONFIG)
    if (crbConfig === 'undefined') {
      return null as CRBConfigDto
    }
    return JSON.parse(crbConfig) as CRBConfigDto
  }

  public getBrandConfigSettings(): CRBConfigSettingDto[] {
    const crbConfigSettings = localStorage.getItem(this.SITE_BRAND_CONFIG_SETTINGS)
    if (crbConfigSettings === 'undefined') {
      return null as CRBConfigSettingDto[]
    }
    return JSON.parse(crbConfigSettings) as CRBConfigSettingDto[]
  }

  public setBrandConfigSettings(brandConfigSettings: CRBConfigSettingDto[]): void {
    const jsonCRBConfig = JSON.stringify(brandConfigSettings)
    localStorage.setItem(this.SITE_BRAND_CONFIG_SETTINGS, jsonCRBConfig)
  }

  public setStatementsCache = (
    selectedDateRange: string
  ): void => {
    localStorage.setItem(
      `${this.SLP_STATEMENTS_PAGE}`,
      JSON.stringify({
        selectedDateRange: selectedDateRange
      })
    )
  }

  public getStatementsCache(): string {
    const lastSearch = localStorage.getItem(`${this.SLP_STATEMENTS_PAGE}`)
    if (lastSearch != null) return JSON.parse(lastSearch)['selectedDateRange'] as string
    else return null
  }

  public clearLocalStorageCache = (): void => {
    Object.keys(localStorage)
      .filter((x) => x.startsWith('slp'))
      .forEach((x) => localStorage.removeItem(x))

    Object.keys(sessionStorage)
      .filter((x) => x.startsWith('sslp'))
      .forEach((x) => sessionStorage.removeItem(x))
  }

    
}
